<template>
    <div class="gallery-container">
        <div class="main-image-container">
            <div class="main-image">
                <img id="main-image" src=""/>
                <div id="image-notes-container" class="image-notes-container hide-notes">
                    <div id="image-notes">
                        Undefined
                    </div>
                </div>
            </div>
        </div>

        <div class="gallery-navigation">
            <div class="arrow down hover-grow">
                <img :src="require('@/assets/plugins/carousel/images/arrowLeft.svg')">
            </div>

            <div class="image-selector">
                <img class="nav-image previous" src="">
                <img class="nav-image center" src="">
                <img class="nav-image next" src="">
            </div>

            <div class="arrow up hover-grow">
                
                <img :src="require('@/assets/plugins/carousel/images/arrowRight.svg')" >
            </div>
        </div>
    </div>
</template>

<script>
import initialise, {cleanUp} from '@/assets/plugins/carousel/carouselVue.js';

export default {
    name: 'CarouselVue',
    props: {
        imageList: {
            default: () => {[]},
            type: Array,
        },
        carouselData: {
            type: Object,
            default: () => {},
        }
    },
    mounted() {
        initialise(this.imageList, this.carouselData);
    },
    beforeDestroy() {
        cleanUp();
    },
    
}
</script>

<style scoped>
  
html {
    box-sizing: border-box;
    background-color: #242425;
}
*, *:before, *:after {
    box-sizing: inherit;
}

body {
    margin: 0;
    padding: 0;
}

.gallery-container {
    width: 100%;
    max-width: 900px;
    min-width: 330px;
}

#main-image {
    display: block;
    border: 4px solid #fff;
    border-radius: 4px;
}

.main-image-container {
    position: relative;
}

.image-notes-container {
    position: absolute;
    top: 4px;
    left: 4px;
    bottom: 4px;
    right: 4px;
    opacity: 0;
    transition: opacity .5s;
}

#image-notes {
    display: flex;
    position: absolute;
    bottom: 0;
    background: #000;
    width: 100%;
    height: 15%;
    padding: 5px;
    color: #FFF;
    justify-content: center;
    text-align: center;
    align-items: center;
    min-height: 50px;
}

.image-notes-container:not(.hide-notes):hover{
    opacity: 0.8;
}

.gallery-container img {
    width: 100%;
}

.gallery-navigation {
    padding: 1px 2px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    border: 3px solid #000; 
    border-radius: 8px;
    background: #000;
}

.image-selector {
    justify-content: center;
    display: flex;
    width: 80%;
    max-height: 150px;
}

.arrow {
    border: 4px solid #ffdc14;
    border-radius: 6px;
    width: 10%;
    height: 100%;
    cursor: pointer;
    background-color: #ffdc14; 
    filter: brightness(80%);
    user-select: none;
}

.arrow img {
    padding: 2px;
}

.image-selector img {
    width: 33.33%;
    cursor: pointer;
}

.nav-image {
    user-select: none;
    height: 90%;
}

.nav-image.previous {
    border: #fff 4px solid;
    transform: scale(0.9);
    margin-right: -10px;
    filter: brightness(60%);
}

.nav-image.next {
    border: #fff 4px solid;
    transform: scale(0.9);
    margin-left: -10px;
    filter: brightness(60%);
}

.nav-image.center {
    position: relative;
    z-index: 1;
    border: #ffdc14 4px solid;
    border-radius: 4px;
}

.hover-grow:hover {
    transform: scale(1.1);
    filter: brightness(100%);
}

.hover-grow {
    transition: transform .2s;
}

</style>


