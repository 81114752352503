import { render, staticRenderFns } from "./CarouselVueComp.vue?vue&type=template&id=b8f95086&scoped=true"
import script from "./CarouselVueComp.vue?vue&type=script&lang=js"
export * from "./CarouselVueComp.vue?vue&type=script&lang=js"
import style0 from "./CarouselVueComp.vue?vue&type=style&index=0&id=b8f95086&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8f95086",
  null
  
)

export default component.exports