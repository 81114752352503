let imageURLs = [];
let navScroll = 3;
let navImages = [];
let collection;
let upArrow;
let downArrow;
let overlayText;
let imageText
let intervalId;
let outerContainer;
let mainImage;
let settings = {
    folder: '',
};

function getNewNavImages(newIdx) {
    const previousIdx =  newIdx - 1 > -1 ? newIdx - 1 : imageURLs.length - 1;
    const nextIdx =  newIdx + 1 <= imageURLs.length - 1 ? newIdx + 1 : 0;
    let tempList =  [imageURLs[previousIdx], imageURLs[newIdx], imageURLs[nextIdx]];
    
    return [
        {
            idxNumber: previousIdx,
            url: tempList[0],
        },
        {
            idxNumber: newIdx,
            url: tempList[1],
        },
        {
            idxNumber: nextIdx,
            url: tempList[2],
        },
    
    ];
    
}

function setNavImages() {
    for (let i = 0; i < collection.length; i++) {
        collection[i].src = navImages[i].url;
    }
}

function setAllImages() {
    navImages = getNewNavImages(navScroll);
    setNavImages();
    setOverlayText();
    mainImage.src = imageURLs[navScroll];
}

let imagesCallBacks = [];

function setOverlayText() {
    const text = imageText[navScroll];
    const element = document.getElementById("image-notes-container");
    if (!text) {
        element.classList.add("hide-notes");
        return;
    }
    element.classList.remove("hide-notes"); 
    overlayText.textContent = text;
}

function initNavImages(i) {
    navScroll = navImages[i].idxNumber;
    setAllImages();
        
}

function upArrowCallBack() {
    navScroll =  navScroll < imageURLs.length - 1 ? navScroll += 1 : 0;
    setAllImages();
}

function downArrowCallBack() {
    navScroll =  navScroll != 0 ? navScroll -= 1 : imageURLs.length - 1;
    setAllImages();
}

function startAutoPlay() {
    if (!intervalId) intervalId = setInterval(upArrowCallBack, 4000);
}

function stopAutoPlay() {
    clearInterval(intervalId);
    intervalId = null;
}

function cleanUp() {
    upArrow.removeEventListener('click', upArrowCallBack);
    downArrow.removeEventListener('click', downArrowCallBack);
    for (let i = 0; i < collection.length; i++) { 
        collection[i].removeEventListener("click", imagesCallBacks[i])
    }
    stopAutoPlay();
    outerContainer.removeEventListener('mouseover', stopAutoPlay);
    outerContainer.removeEventListener('mouseout', startAutoPlay);
}

export default function (imagesData, importedSettings) {
    settings = importedSettings;
    const folder = settings.folder;
    imageURLs = imagesData.map(imageData => {
        if (typeof imageData === 'string') return imageData;
        if (typeof imageData === 'object') return require(`@/assets/${folder}/${imageData.imagePath}`);
    });
    imageText = imagesData.map(imageData => imageData.descriptionText);
    navImages = getNewNavImages(navScroll);
    collection = document.getElementsByClassName("nav-image");
    overlayText = document.getElementById("image-notes");
    upArrow = document.getElementsByClassName("arrow up")[0];
    downArrow = document.getElementsByClassName("arrow down")[0];
    upArrow.addEventListener("click", upArrowCallBack)
    downArrow.addEventListener("click", downArrowCallBack);
    setNavImages();
    setOverlayText();
    for (let i = 0; i < collection.length; i++) { 
        const selectedImage = collection[i];
        imagesCallBacks.push(() => initNavImages(i));
        selectedImage.addEventListener("click", imagesCallBacks[i])
    }
    mainImage = document.getElementById("main-image");
    mainImage.src = imageURLs[navScroll];
    outerContainer = document.getElementsByClassName("gallery-container")[0];
    outerContainer.addEventListener('mouseover', stopAutoPlay);
    outerContainer.addEventListener('mouseout', startAutoPlay);
}

export {cleanUp}